<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		class="w-100 store">
		<b-card-group
			v-if="!isAdminOrCorporate"
			id="our-products">
			<b-card class="border-0 bg-white mb-0 h-100">
				<div class="col-12 pt-5 mt-3">
					<p
						:class="windowWidth === 'xs' ? 'h3' : 'big-label'"
						class="text-center font-weight-bold bolder-text text-primary">
						{{ translate('pickup_store_title') }}
					</p>
					<p
						:class="windowWidth === 'xs' ? 'h5' : 'h4'"
						class="text-center text-muted">
						{{ translate('our_products_subtitle') }}
					</p>
				</div>
				<div
					v-if="loadingProducts"
					class="d-flex justify-content-center align-items-center pt-5 h-100">
					<is-loading
						:loading="loadingProducts"
						:loading-label="translate('loading')"
						:no-data-label="translate('data_not_found')" />
				</div>
				<div v-else>
					<store-products-banner
						:products-import="[TN]"
						:show-price="[false]" />
					<div
						v-if="hasSwagProducts"
						class="row mx-auto py-4">
						<div
							:class="['xs','sm', 'md'].includes(windowWidth) ? ' px-1' : 'px-4'"
							class="row mx-auto container">
							<div
								class="w-100 mx-auto"
								:class="['xs','sm'].includes(windowWidth) ? 'scrolling-wrapper' : 'row justify-content-around'">
								<div
									v-for="(item, index) in [...swagProductsData, ...otherProductsData]"
									:key="index"
									class="text-center my-3"
									:class="['xs','sm'].includes(windowWidth) ? 'd-inline-block' : 'col-md-4 col-lg-3 d-flex flex-column mb-5'">
									<img
										:src="item.image"
										:class="['xs', 'sm'].includes(windowWidth) ? 'img-max-width' : 'mh-100'"
										:style="!['xs', 'sm'].includes(windowWidth) ? 'height: 200px;' : ''"
										class="mx-auto mb-3 px-2 pointer img-fluid"
										@click="$router.push({ name: 'PSProductDetails', params: { productName: item.product_name } })">
									<div class="mt-2">
										<p class="h6 text-muted bolder-title">
											{{ translate(item.code_name) }}
										</p>
										<p class="h4">
											{{ getProductPrice(item) }}
										</p>
										<!-- <p
											v-if="item.exchange"
											class="exchange-text h6">
											{{ item.exchange_price }}
										</p> -->
										<button
											type="button"
											:class="['md'].includes(windowWidth) ? ' w-75' : 'w-50'"
											class="btn btn-outline-primary btn-rounded mt-3"
											@click="$router.push({ name: 'PSProductDetails', params: { productName: item.product_name } })">
											{{ translate('see_more') }}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</b-card>
		</b-card-group>
	</div>
</template>
<script>

import { sidebarCssClasses } from '@coreui/vue/src/shared/classes';
import cookie from 'vue-cookie';
import ProductMix from '@/mixins/PhysicalStore/Products';
import { Store, Validations, Products } from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';
import Cart from '@/util/PhysicalStore/Cart';
import EventBus from '@/util/eventBus';
import StoreProductsBanner from './components/StoreProductsBanner';

export default {
	name: 'Store',
	messages: [Store, Validations, Products],
	components: { StoreProductsBanner },
	mixins: [ProductMix, WindowSizes],
	data() {
		return {
			addProducts: new Cart(),
			createCart: new Cart(),
			alert: new this.$Alert(),
		};
	},
	computed: {
		addProductsLoading() {
			return !!this.addProducts.data.loading;
		},
		wearablesProductsData() {
			try {
				const products = [];
				this.wearablesProducts.forEach((item) => {
					let { price } = item.attributes;
					let exchangePrice = item.attributes.exchange_price;
					if (this.hasDiscount(item)) {
						price = this.discount(item).value;
						exchangePrice = this.discount(item).exchange_price;
					}
					products.push({
						image: item.attributes.image,
						sku: item.attributes.sku,
						code_name: item.attributes.code_name,
						price,
						quantity: 1,
						exchange: item.attributes.exchange,
						exchangePrice,
						decoded_name: item.attributes.decoded_name,
						thumbnail: item.attributes.thumbnail,
						selectable: item.attributes.selectable,
					});
				});
				return products;
			} catch (error) {
				return [];
			}
		},
		swagProductsData() {
			try {
				const products = [];
				this.swagProducts.forEach((item) => {
					let { price } = item.attributes;
					let exchangePrice = item.attributes.exchange_price;
					if (this.hasDiscount(item)) {
						price = this.discount(item).value;
						exchangePrice = this.discount(item).exchange_price;
					}
					products.push({
						image: item.attributes.image,
						sku: item.attributes.sku,
						code_name: item.attributes.code_name,
						price,
						quantity: 1,
						exchange: item.attributes.exchange,
						exchangePrice,
						product_name: item.attributes.decoded_name,
					});
				});
				return products;
			} catch (error) {
				return [];
			}
		},
		TN() {
			try {
				const product = this.wearablesProductsData.find((item) => item.sku === 'TN');
				product.bannerContent = {
					description: this.translate(`${product.code_name}_description`),
					titleClass: 'text-primary',
					descriptionClass: 'font-weight-normal',
					buttonClass: 'btn-primary',
				};
				return product;
			} catch (e) {
				return null;
			}
		},
		otherProductsData() {
			try {
				const products = [];
				this.otherProducts.forEach((item) => {
					if (item.attributes.code_name !== 'ON1001') { //	Skip Organic Networker - Soft cover
						let { price } = item.attributes;
						let exchangePrice = item.attributes.exchange_price;
						if (this.hasDiscount(item)) {
							price = this.discount(item).value;
							exchangePrice = this.discount(item).exchange_price;
						}

						products.push({
							image: item.attributes.image,
							sku: item.attributes.sku,
							code_name: item.attributes.code_name,
							price,
							quantity: 1,
							exchange: item.attributes.exchange,
							exchangePrice,
							product_name: item.attributes.decoded_name,
							thumbnail: item.attributes.thumbnail,
							selectable: item.attributes.selectable,
						});
					}
				});
				return products;
			} catch (error) {
				return [];
			}
		},
	},
	watch: {
		country(newVal, oldVal) {
			let timeout = 0;
			if (oldVal === null) {
				timeout = 1500;
			}
			setTimeout(() => {
				this.prodData.getProducts(this.getStoredCountry());
			}, timeout);
		},
	},
	created() {
		if (typeof this.$user.details().package === 'string') {
			this.currentPackage = this.$user.details().package;
		}
		sidebarCssClasses.map((item) => document.body.classList.remove(item));
	},
	mounted() {
		EventBus.$emit('storeViewMounted');
	},
	methods: {
		getProductOptions(product) {
			const options = { image: product.thumbnail };
			return options;
		},
		scrollTo(id) {
			EventBus.$emit('scrollTo', id);
		},
		async addProduct(products) {
			if (!this.addProductsLoading) {
				try {
					await this.addProducts.addCartProducts(cookie.get('cart_id'), { products });
					EventBus.$emit('updateLayoutStoreTotals');
				} catch (e) {
					if (typeof this.addProducts.errors.errors.products !== 'undefined') {
						let response = '';
						this.addProducts.errors.errors.products.forEach((item) => { response += `${item} \n`; });
						this.alert.toast('error', response, { timer: 4000 });
					} else {
						this.alert.toast('error', this.translate('default_error_message'));
					}
				}
			}
		},
	},
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Montserrat');

.store  html body div {
	font-family: 'Montserrat', sans-serif !important;
	font-weight: 600 !important;
}

p h5 {
	font-family: 'Montserrat', sans-serif !important;
	font-weight: 400 !important;
}
.img-max-width{
	max-width: 90%;
}
.btn-rounded {
	border-radius: 25px;
}
</style>
<style>
.big-label{
	font-size: 55px;
}
.custom-btn.custom-white-btn{
	border: 1px solid white;
	width: auto;
	border-radius: 25px;
	background: transparent;
	color: white;
}
.custom-btn.custom-white-btn:hover{
	background: white;
	color: black;
}

.custom-btn.custom-primary-btn{
	width: 150px;
	border-radius: 25px;
}

.custom-hr{
	opacity: 0.1;
	border-color: white;
}

.vnis__input.vnis-custom-input-width{
	width: 90px;
}

.vnis.vnis-custom-dimensions {
	width: 170px;
	height: 30px;
}
.black-overlay{
	background: rgba(23, 23, 25, 0.5);
}
.play-icon{
	min-width: 75px;
	transition: all .3s ease-in-out;
}
.play-icon:hover{
	transform: scale(1.1);
}
.bolder-text {
	-webkit-text-stroke: 1px #DF703D;
}
</style>
